<template>
  <KTLoader v-if="loaderEnabled" :logo="loaderLogo"></KTLoader>
  <!-- begin:: Body -->
  <div class="page d-flex flex-row flex-column-fluid">
    <!-- begin:: Aside Left -->
    <KTAside v-if="asideEnabled" :lightLogo="themeLightLogo" :darkLogo="themeDarkLogo"></KTAside>
    <!-- end:: Aside Left -->

    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <KTHeader :title="pageTitle" showToolbar>
        <KTToolbar v-if="subheaderDisplay" :breadcrumbs="breadcrumbs" title="Active Projects" />
      </KTHeader>

      <!-- begin:: Content -->
      <div id="kt_content" class="content d-flex flex-column flex-column-fluid px-5">
        <!-- begin:: Content Head -->

        <!-- end:: Content Head -->

        <!-- begin:: Content Body -->
        <router-view />
        <!-- end:: Content Body -->
      </div>
      <!-- end:: Content -->
      <KTFooter></KTFooter>
    </div>
  </div>
  <!-- end:: Body -->
  <KTScrollTop></KTScrollTop>
  <ViewTenantsModal></ViewTenantsModal>
</template>


<script lang="ts">
import {
  defineComponent,
  computed,
  onMounted,
  watch,
  ref,
  watchEffect,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import ViewTenantsModal from "@/components/modals/general/ViewTenantsModal.vue";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

export default defineComponent({
  name: "Layout",
  components: {
    KTLoader, KTAside, KTHeader, KTFooter, KTScrollTop, KTToolbar, ViewTenantsModal
  },
  setup() {
    const store = useStore();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });
    return {
      KTAside,
      KTHeader,
      KTFooter,
      KTToolbar,
      KTScrollTop,
      KTLoader,
      loaderEnabled,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      themeLightLogo,
      themeDarkLogo,
      pageTitle,
      breadcrumbs,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/sass/style.scss";
// @import "../assets/sass/custom-style.scss";
</style>

<style>
.grecaptcha-badge {
  visibility: hidden !important;
}
</style>